import { computed, ref } from "@vue/composition-api";
import store from "@/store";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
import i18n from '@/libs/i18n'
/*
import AccelerationValuesData from "../../dummy-db/AccelerationValues.json"
import AxialVibrationValuesData from "../../dummy-db/AxialVibrationValues.json"
import RadialVibrationValuesData from "../../dummy-db/RadialVibrationValues.json"
import RadialAxisKurtosisValuesData from "../../dummy-db/RadialAxisKurtosisValues.json"
*/
export default function getPlantLineWidgetGroup() {
    const TemperatureValuesOptions = computed(() => {
        const data = store.getters["pamis-maintenance/getTemperatureValuesData"];
        //const dataState = GetDataStatus(data);
        //if (dataState.response_status === "success") {
        const series = [];
        const downTimeCategories = [];
        data.forEach(downTime => {
            if (!downTimeCategories.includes(downTime.name)) {
                downTimeCategories.push(downTime.name);
            }
        })
        const categories = [i18n.t("Offline"), i18n.t("Healthy"), i18n.t("Warning"), i18n.t("Danger")]
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        for (let i = 0; i < data.length; i++) {
            for (let wc in workcenters) {
                if (data[i][workcenters[wc]] == 0) {
                    series.push({ name: categories[0], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] > 0 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.3) {
                    series.push({ name: categories[1], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] >= data[0][workcenters[wc]] * 1.3 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.6) {
                    series.push({ name: categories[2], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else {
                    series.push({ name: categories[3], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
            }
        }

        const chartSeries = [];
        let categorizedValues = []
        for (let i = 0; i < categories.length; i++) {
            categorizedValues = series.filter(e => { return e.name == categories[i] })
            if (categorizedValues.length > 0) {
                chartSeries.push({
                    name: categorizedValues[0].name, data: categorizedValues.map(e => {
                        return {
                            x: e.x,
                            y: e.y,
                            val: `${e.val} °C`
                        }
                    })
                })
            }
        }
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            series: chartSeries, widgetType: "ApexChartTimeline",
            title: {
                text: 'Temperature Values for All Sensors',
                align: "center",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: "20px",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    color: "#263238",
                },
            },
        };
        return options;
        /*} else {
            return dataState;
        }*/
    });

    const AxialVibrationValuesOptions = computed(() => {
        const data = store.getters["pamis-maintenance/getAxialVibrationValuesData"];
        const series = [];
        const downTimeCategories = [];
        data.forEach(downTime => {
            if (!downTimeCategories.includes(downTime.name)) {
                downTimeCategories.push(downTime.name);
            }
        })
        const categories = [i18n.t("Offline"), i18n.t("Healthy"), i18n.t("Warning"), i18n.t("Danger")]
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        for (let i = 0; i < data.length; i++) {
            for (let wc in workcenters) {
                if (data[i][workcenters[wc]] == 0) {
                    series.push({ name: categories[0], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] > 0 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.3) {
                    series.push({ name: categories[1], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] >= data[0][workcenters[wc]] * 1.3 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.6) {
                    series.push({ name: categories[2], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else {
                    series.push({ name: categories[3], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }

            }
        }

        const chartSeries = [];
        let categorizedValues = []
        for (let i = 0; i < categories.length; i++) {
            categorizedValues = series.filter(e => { return e.name == categories[i] })
            if (categorizedValues.length > 0) {
                chartSeries.push({
                    name: categorizedValues[0].name, data: categorizedValues.map(e => {
                        return {
                            x: e.x,
                            y: e.y,
                            val: `${e.val} mm/s`
                        }
                    })
                })
            }

        }
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            series: chartSeries, widgetType: "ApexChartTimeline",
            title: {
                text: 'Axial Vibration Values for All Sensors',
                align: "center",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: "20px",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    color: "#263238",
                },
            },
        };
        return options;
        /*} else {
            return dataState;
        }*/
    });

    const RadialVibrationValuesOptions = computed(() => {
        const data = store.getters["pamis-maintenance/getRadialVibrationValuesData"];
        const series = [];
        const downTimeCategories = [];
        data.forEach(downTime => {
            if (!downTimeCategories.includes(downTime.name)) {
                downTimeCategories.push(downTime.name);
            }
        })
        const categories = [i18n.t("Offline"), i18n.t("Healthy"), i18n.t("Warning"), i18n.t("Danger")]
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        for (let i = 0; i < data.length; i++) {
            for (let wc in workcenters) {
                if (data[i][workcenters[wc]] == 0) {
                    series.push({ name: categories[0], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] > 0 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.3) {
                    series.push({ name: categories[1], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] >= data[0][workcenters[wc]] * 1.3 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.6) {
                    series.push({ name: categories[2], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else {
                    series.push({ name: categories[3], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }

            }
        }

        const chartSeries = [];
        let categorizedValues = []
        for (let i = 0; i < categories.length; i++) {
            categorizedValues = series.filter(e => { return e.name == categories[i] })
            if (categorizedValues.length > 0) {
                chartSeries.push({
                    name: categorizedValues[0].name, data: categorizedValues.map(e => {
                        return {
                            x: e.x,
                            y: e.y,
                            val: `${e.val} mm/s`
                        }
                    })
                })
            }

        }
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            series: chartSeries, widgetType: "ApexChartTimeline",
            title: {
                text: 'Radial Vibration Values for All Sensors',
                align: "center",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: "20px",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    color: "#263238",
                },
            },
        };
        return options;
        /*} else {
            return dataState;
        }*/
    });

    const AccelerationValuesOptions = computed(() => {
        const data = store.getters["pamis-maintenance/getAccelerationValuesData"];
        const series = [];
        const downTimeCategories = [];
        data.forEach(downTime => {
            if (!downTimeCategories.includes(downTime.name)) {
                downTimeCategories.push(downTime.name);
            }
        })
        const categories = [i18n.t("Offline"), i18n.t("Healthy"), i18n.t("Warning"), i18n.t("Danger")]
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        for (let i = 0; i < data.length; i++) {
            for (let wc in workcenters) {
                if (data[i][workcenters[wc]] == 0) {
                    series.push({ name: categories[0], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] > 0 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.3) {
                    series.push({ name: categories[1], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] >= data[0][workcenters[wc]] * 1.3 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.6) {
                    series.push({ name: categories[2], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else {
                    series.push({ name: categories[3], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
            }
        }

        const chartSeries = [];
        let categorizedValues = []
        for (let i = 0; i < categories.length; i++) {
            categorizedValues = series.filter(e => { return e.name == categories[i] })
            if (categorizedValues.length > 0) {
                chartSeries.push({
                    name: categorizedValues[0].name, data: categorizedValues.map(e => {
                        return {
                            x: e.x,
                            y: e.y,
                            val: `${e.val} g`
                        }
                    })
                })
            }

        }
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            series: chartSeries, widgetType: "ApexChartTimeline",
            title: {
                text: 'Acceleration Values for All Sensors',
                align: "center",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: "20px",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    color: "#263238",
                },
            },
        };
        return options;
        /*} else {
            return dataState;
        }*/
    });

    const RadialAxisKurtosisValuesOptions = computed(() => {
        const data = store.getters["pamis-maintenance/getRadialAxisKurtosisValuesData"];
        const series = [];
        const downTimeCategories = [];
        data.forEach(downTime => {
            if (!downTimeCategories.includes(downTime.name)) {
                downTimeCategories.push(downTime.name);
            }
        })
        const categories = [i18n.t("Offline"), i18n.t("Healthy"), i18n.t("Warning"), i18n.t("Danger")]
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        for (let i = 0; i < data.length; i++) {
            for (let wc in workcenters) {
                if (data[i][workcenters[wc]] == 0) {
                    series.push({ name: categories[0], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] > 0 && data[i][workcenters[wc]] < data[0][workcenters[wc]] * 1.3) {
                    series.push({ name: categories[1], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else if (data[i][workcenters[wc]] >= data[0][workcenters[wc]] * 1.6) {
                    series.push({ name: categories[2], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
                else {
                    series.push({ name: categories[3], x: workcenters[wc], y: calculateDateRange(i, data, data.length), val: data[i][workcenters[wc]] })
                }
            }
        }

        const chartSeries = [];
        let categorizedValues = []
        for (let i = 0; i < categories.length; i++) {
            categorizedValues = series.filter(e => { return e.name == categories[i] })
            if (categorizedValues.length > 0) {
                chartSeries.push({
                    name: categorizedValues[0].name, data: categorizedValues.map(e => {
                        return {
                            x: e.x,
                            y: e.y,
                            val: `${e.val} `
                        }
                    })
                })
            }

        }
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            series: chartSeries, widgetType: "ApexChartTimeline",
            title: {
                text: 'Radial Axis Kurtosis Values for All Sensors',
                align: "center",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: "20px",
                    fontWeight: 500,
                    fontFamily: "Helvetica",
                    color: "#263238",
                },
            },
        };
        return options;
        /*} else {
            return dataState;
        }*/
    });


    function calculateDateRange(index, data, arrSize) {
        if (index >= arrSize - 1) {
            let startDate = new Date(data[index].Time);
            return [startDate.getTime(), startDate.getTime() + 1000 * 60]
        }
        else {
            let startDate = new Date(data[index].Time);
            let endDate = new Date(data[index + 1].Time);
            return [startDate.getTime(), endDate.getTime()];
        }
    }
    const ConvertXAxisDateColumnChart = function (seriesData) {
        //parameter format must be [{name(CategoryName),x(Date),y(Value)},]
        let chartSeries = [];
        let categories = [];
        for (let i in seriesData) {
            if (!categories.includes(seriesData[i].name)) {
                categories.push(seriesData[i].name);
            }
        }
        for (let c in categories) {
            let categoryDataArr = [];
            let chartSeriesObject = {};
            let chartDataValues = [];
            categoryDataArr = seriesData.filter(x => x.name == categories[c]);
            chartDataValues = categoryDataArr.map(key => {
                let date = new Date(key.x);
                return {
                    x: date.getTime(),
                    y: key.y,
                };
            });
            chartDataValues.sort((a, b) => {
                return b.x - a.x;
            });
            chartSeriesObject = { name: categories[c], data: chartDataValues };
            chartSeries.push(chartSeriesObject);
        }
        return chartSeries;
    };
    const TemperatureValuesLineOptions = computed(() => {
        let data = store.getters["pamis-maintenance/getTemperatureValuesData"];
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        const seriesValues = []
        for (let i = 0; i < data.length; i++) {
            for (let wc = 0; wc < workcenters.length; wc++) {
                seriesValues.push({ name: workcenters[wc], x: data[i].Time, y: data[i][workcenters[wc]] })
            }
        }
        let chartSeries = [];
        //find categories
        chartSeries = ConvertXAxisDateColumnChart(seriesValues);
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            response_status: "success",
            widgetType: "HighchartLine",
            title: {
                text: i18n.t("Temperature Values for All Sensors"),
            },
            xAxis: {
                type: "datetime",
            },
            yAxis: {
                title: { text: i18n.t("Percentile") },
            },
            series: chartSeries,
        };
        return options;

    });
    const RadialVibrationValuesLineOptions = computed(() => {
        let data = store.getters["pamis-maintenance/getRadialVibrationValuesData"];
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        const seriesValues = []
        for (let i = 0; i < data.length; i++) {
            for (let wc = 0; wc < workcenters.length; wc++) {
                seriesValues.push({ name: workcenters[wc], x: data[i].Time, y: data[i][workcenters[wc]] })
            }
        }
        let chartSeries = [];
        //find categories
        chartSeries = ConvertXAxisDateColumnChart(seriesValues);
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            response_status: "success",
            widgetType: "HighchartLine",
            title: {
                text: i18n.t("Radial Vibration Values for All Sensors"),
            },
            xAxis: {
                type: "datetime",
            },
            yAxis: {
                title: { text: i18n.t("Percentile") },
            },
            series: chartSeries,
        };
        return options;

    });
    const RadialAxisKurtosisValuesLineOptions = computed(() => {
        let data = store.getters["pamis-maintenance/getRadialAxisKurtosisValuesData"];
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        const seriesValues = []
        for (let i = 0; i < data.length; i++) {
            for (let wc = 0; wc < workcenters.length; wc++) {
                seriesValues.push({ name: workcenters[wc], x: data[i].Time, y: data[i][workcenters[wc]] })
            }
        }
        let chartSeries = [];
        //find categories
        chartSeries = ConvertXAxisDateColumnChart(seriesValues);
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            response_status: "success",
            widgetType: "HighchartLine",
            title: {
                text: i18n.t("Radial Axis Kurtosis Values for All Sensors"),
            },
            xAxis: {
                type: "datetime",
            },
            yAxis: {
                title: { text: i18n.t("Percentile") },
            },
            series: chartSeries,
        };
        return options;

    });
    const AxialVibrationValuesLineOptions = computed(() => {
        let data = store.getters["pamis-maintenance/getAxialVibrationValuesData"];
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        const seriesValues = []
        for (let i = 0; i < data.length; i++) {
            for (let wc = 0; wc < workcenters.length; wc++) {
                seriesValues.push({ name: workcenters[wc], x: data[i].Time, y: data[i][workcenters[wc]] })
            }
        }
        let chartSeries = [];
        //find categories
        chartSeries = ConvertXAxisDateColumnChart(seriesValues);
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            response_status: "success",
            widgetType: "HighchartLine",
            title: {
                text: i18n.t("Axial Vibration Values for All Sensors"),
            },
            xAxis: {
                type: "datetime",
            },
            yAxis: {
                title: { text: i18n.t("Percentile") },
            },
            series: chartSeries,
        };
        return options;

    });
    const AccelerationValuesLineOptions = computed(() => {
        let data = store.getters["pamis-maintenance/getAccelerationValuesData"];
        const workcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
        const seriesValues = []
        for (let i = 0; i < data.length; i++) {
            for (let wc = 0; wc < workcenters.length; wc++) {
                seriesValues.push({ name: workcenters[wc], x: data[i].Time, y: data[i][workcenters[wc]] })
            }
        }
        let chartSeries = [];
        //find categories
        chartSeries = ConvertXAxisDateColumnChart(seriesValues);
        let keyVal = Math.random() * 100000000
        let options = {
            key: keyVal,
            response_status: "success",
            widgetType: "HighchartLine",
            title: {
                text: i18n.t("Acceleration Values for All Sensors"),
            },
            xAxis: {
                type: "datetime",
            },
            yAxis: {
                title: { text: i18n.t("Percentile") },
            },
            series: chartSeries,
        };
        return options;

    });
    return {
        TemperatureValuesOptions,
        AxialVibrationValuesOptions,
        AccelerationValuesOptions,
        RadialAxisKurtosisValuesOptions,
        RadialVibrationValuesOptions,
        TemperatureValuesLineOptions,
        AccelerationValuesLineOptions,
        RadialVibrationValuesLineOptions,
        AxialVibrationValuesLineOptions,
        RadialAxisKurtosisValuesLineOptions,
    }
}
