<template>
  <div>
    <b-card><RadialVibration :data="RadialVibrationValuesOptions" /></b-card>
    <b-card><AxialVibration :data="AxialVibrationValuesOptions" /></b-card>
    <b-card><Temperature :data="TemperatureValuesOptions" /></b-card>
    <b-card><Acceleration :data="AccelerationValuesOptions" /></b-card>
    <b-card>
      <RadialAxisKurtosis :data="RadialAxisKurtosisValuesOptions" />
    </b-card>

    <RadialVibrationHighchart :data="RadialVibrationValuesLineOptions" />
    <AxialVibrationHighchart :data="AxialVibrationValuesLineOptions" />
    <TemperatureHighchart :data="TemperatureValuesLineOptions" />
    <AccelerationHighchart :data="AccelerationValuesLineOptions" />
    <RadialAxisKurtosisHighchart :data="RadialAxisKurtosisValuesLineOptions" />
    <b-row>
      <b-col cols="6"><DevicesAndResultsList /></b-col>
      <b-col cols="6"><MachineLearningResultsList /></b-col>
    </b-row>
  </div>
</template>
  
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import { computed, ref } from "@vue/composition-api";
//import ApexChartTimeline from "./components/widgets/ApexChartTimeline.vue";
//import HighchartLine from "./components/widgets/HighchartLine.vue";
//import DevicesAndResultsList from "./components/widgets/ListNormal.vue";
//import MachineLearningResultsList from "./components/widgets/ListNormalRight.vue";
import usePredictiveMaintenance from "./components/composables/usePredictiveMaintenance.js";
import store from "@/store";
import maintenanceStoreModule from "../maintenanceStoreModule";
import Vue from "vue";
const isLoaded = ref(false);
const importAsync = (widgetName, timeoutDuration) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(import(`./components/widgets/${widgetName}.vue`));
    }, timeoutDuration);
  });
};

export default {
  components: {
    BCard,
    BRow,
    BCol,
    RadialVibration: async () => await importAsync("ApexChartTimeline", 0),
    AxialVibration: async () => await importAsync("ApexChartTimeline", 900),
    Temperature: async () => await importAsync("ApexChartTimeline", 1700),
    Acceleration: async () => await importAsync("ApexChartTimeline", 2300),
    RadialAxisKurtosis: async () =>
      await importAsync("ApexChartTimeline", 2900),
    RadialVibrationHighchart: async () =>
      await importAsync("HighchartLine", 2900),
    AxialVibrationHighchart: async () =>
      await importAsync("HighchartLine", 3000),
    TemperatureHighchart: async () => await importAsync("HighchartLine", 3100),
    AccelerationHighchart: async () => await importAsync("HighchartLine", 3200),
    RadialAxisKurtosisHighchart: async () =>
      await importAsync("HighchartLine", 3300),
    DevicesAndResultsList: async () => await importAsync("ListNormal", 3400),
    MachineLearningResultsList: async () =>
      await importAsync("ListNormalRight", 3400),
  },

  setup() {
    const PAMIS_MODULE_NAME = "pamis-maintenance";

    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, maintenanceStoreModule);

    const {
      TemperatureValuesOptions,
      AccelerationValuesOptions,
      AxialVibrationValuesOptions,
      RadialAxisKurtosisValuesOptions,
      RadialVibrationValuesOptions,
      TemperatureValuesLineOptions,
      AccelerationValuesLineOptions,
      RadialVibrationValuesLineOptions,
      AxialVibrationValuesLineOptions,
      RadialAxisKurtosisValuesLineOptions,
    } = usePredictiveMaintenance();
    const isSimulationRunning = computed(() => {
      return store.getters["pamis-maintenance/getSimulationStatus"];
    });
    return {
      isSimulationRunning,
      isLoaded,
      AccelerationValuesOptions,
      AxialVibrationValuesOptions,
      TemperatureValuesOptions,
      RadialAxisKurtosisValuesOptions,
      RadialVibrationValuesOptions,
      TemperatureValuesLineOptions,
      AccelerationValuesLineOptions,
      RadialVibrationValuesLineOptions,
      AxialVibrationValuesLineOptions,
      RadialAxisKurtosisValuesLineOptions,
    };
  },
};
</script>
